import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AssetsAuthApiService } from '../../../../services/api/auth/assets-auth-api.service';
import { AssetType, IAsset } from '../../../../models/defines';
import { FolderNameDialog } from '../../../../components/dialogs/folder-name/folder-name-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmComponent } from '../../../../components/dialogs/confirm/confirm.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenerateImageComponent } from '../../../../components/dialogs/generate-image/generate-image.component';
import { CreativeAuthApiService } from '../../../../services/api/auth/creative-auth-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  @Input() hosted: boolean = false;

  selectedFolder = '/';
  selectedFolderSubject = new BehaviorSubject<string>('/');
  folderFiles: IAsset[] = [];
  selectedAsset: IAsset = null;

  foldersSubject = new BehaviorSubject<Array<IAsset>>(null);
  foldersWithoutParents: IAsset[];
  filesOfCurrentFolder: Array<IAsset>;
  cols: number;
  loadingData = false;
  gridByBreakpoint = {
    xl: 6,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1,
  };
  dialogType: AssetType;
  AssetType = AssetType;
  private foldersSubscription: Subscription;
  private selectedFolderSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public assetsApi: AssetsAuthApiService,
    private creativeApi: CreativeAuthApiService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.selectedFolderSubscription = this.selectedFolderSubject.subscribe(
      (newPath) => {
        if (newPath) {
          this.filesOfCurrentFolder = this.getFilesOfCurrentFolder(newPath);
        }
      },
    );

    this.hosted = data?.hosted;
    this.dialogType = data?.dialogType;
    this.foldersSubscription = this.foldersSubject.subscribe((folders) => {
      if (folders) {
        this.selectedFolderSubject.next(this.selectedFolderSubject.value);
        const clone = { ...folders };
        delete clone['/'];
        this.foldersWithoutParents = clone;
      }
    });
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.matches) {
          if (result.breakpoints[Breakpoints.XSmall]) {
            this.cols = this.gridByBreakpoint.xs;
          }
          if (result.breakpoints[Breakpoints.Small]) {
            this.cols = this.gridByBreakpoint.sm;
          }
          if (result.breakpoints[Breakpoints.Medium]) {
            this.cols = this.gridByBreakpoint.md;
          }
          if (result.breakpoints[Breakpoints.Large]) {
            this.cols = this.gridByBreakpoint.lg;
          }
          if (result.breakpoints[Breakpoints.XLarge]) {
            this.cols = this.gridByBreakpoint.xl;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.foldersSubscription.unsubscribe();
    this.selectedFolderSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getAllAssets();
  }

  filterFolderFiles() {
    // Init if not yet initiated
    //this.selectedFolder = this.selectedFolder ?? "/";
    this.selectedFolderSubject.next(this.selectedFolderSubject.value ?? '/');

    // if (this.folders) {
    //   this.folderFiles = this.folders[this.selectedFolder] ?? [];
    // }
    if (this.foldersSubject.value) {
      this.folderFiles =
        this.foldersSubject.value[this.selectedFolderSubject.value] ?? [];
    }
  }

  async getAllAssets() {
    this.loadingData = true;
    let assets: IAsset[];
    if (this.dialogType) {
      assets = await this.assetsApi
        .getAssetsByType(this.dialogType)
        .toPromise();
    } else {
      assets = await this.assetsApi.getAssets().toPromise();
    }

    console.log('all assets', assets);
    let folderized: Array<IAsset> = [];
    assets.forEach((x) => {
      if (x?.folder) {
        /// Initialize
        folderized[x.folder] = folderized[x.folder] ?? [];
        /// Add the file
        folderized[x.folder].push(x);
      }
    });
    //this.folders = folderized;
    console.log('Folderized', folderized);
    this.foldersSubject.next(folderized);

    this.filterFolderFiles();
    this.loadingData = false;
  }

  countNonFolders(folder) {
    if (folder?.value) {
      let filtered = folder.value.filter((x) => {
        return x?.type !== AssetType.FOLDER;
      });
      return `${filtered.length} file${filtered.length === 1 ? '' : 's'}`;
    }
    return '0 files';
  }

  getFoldersWithoutParent() {
    const clone = { ...this.foldersSubject.value };
    delete clone['/'];
    return clone;
  }

  selectFile(file: IAsset) {
    console.log(file);
    if (!file || !this.hosted) {
      return;
    }
    if (this.selectedAsset === file) {
      // Deselect
      this.selectedAsset = null;
    } else {
      this.selectedAsset = file;
    }
  }

  moveFile(file: IAsset, toFolder: string) {
    console.log('to folder', toFolder);

    this.dialog
      .open(ConfirmComponent, {
        minHeight: 'fit-content',
        data: {
          message: `Are you sure you want to move ${file.displayName} to ${toFolder}?`,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.confirmed) {
          console.log('Moving file');
          this.assetsApi
            .moveFile(file.displayName, file.folder, toFolder)
            .subscribe((result) => {
              this._snackBar.open(
                `✌ "${file.displayName}" Moved to ${toFolder}!`,
                'Dismiss',
                { duration: 3000 },
              );
              // console.log(result)
              this.getAllAssets();
            });
        }
      });
  }

  renameFile(file: IAsset) {
    let oldName = file?.displayName + '';
    this.dialog
      .open(FolderNameDialog, {
        minHeight: 'fit-content',
        data: { isNew: false, oldName: oldName, fileMode: true },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.newName) {
          console.log('Sending file name change');
          this.assetsApi
            .renameFile(file.folder, file.displayName, response.newName)
            .subscribe((result) => {
              this._snackBar.open(
                `👌 "${oldName}" Renamed to "${response?.newName}"!`,
                'Dismiss',
                { duration: 3000 },
              );
              // console.log(result)
              this.getAllAssets();
            });
        }
      });
  }

  renameFolder(folderName: string) {
    this.dialog
      .open(FolderNameDialog, {
        minHeight: 'fit-content',
        data: { isNew: false, oldName: folderName, fileMode: false },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.newName) {
          console.log('Sending new folder');
          this.assetsApi
            .renameFolder(folderName, response.newName)
            .subscribe((result) => {
              this._snackBar.open(
                `👌 "${folderName}" Renamed to "${response?.newName}"!`,
                'Dismiss',
                { duration: 3000 },
              );
              // console.log(result)
              this.getAllAssets();
            });
        }
      });
  }

  deleteFile(file: IAsset) {
    this.dialog
      .open(ConfirmComponent, {
        minHeight: 'fit-content',
        data: {
          message: `Are you sure you want to delete ${file.displayName}?`,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.confirmed) {
          console.log('Deleting folder');
          this.assetsApi
            .deleteFile(file.displayName, file.folder)
            .subscribe((result) => {
              this._snackBar.open(
                `🗑️ "${file.displayName}" Deleted"!`,
                'Dismiss',
                { duration: 3000 },
              );
              // console.log(result)
              this.getAllAssets();
            });
        }
      });
  }

  deleteFolder(folderName: string) {
    const numberOfFiles = this.countNonFolders({
      value: this.foldersSubject.value[folderName],
    });
    this.dialog
      .open(ConfirmComponent, {
        minHeight: 'fit-content',
        data: {
          message: `Are you sure you want to delete ${folderName}${
            numberOfFiles ? ` with ${numberOfFiles} in it?` : `?`
          } `,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        //{newName: 'gaegaa', oldName: '', isNew: true}
        console.log(response);
        if (response?.confirmed) {
          console.log('Deleting folder');
          this.assetsApi.deleteFolder(folderName).subscribe((result) => {
            this._snackBar.open(`🗑️ "${folderName}" Deleted"!`, 'Dismiss', {
              duration: 3000,
            });
            // console.log(result)
            this.getAllAssets();
          });
        }
      });
  }

  selectFolder(folderName: string) {
    this.selectedFolderSubject.next(
      folderName ?? this.selectedFolderSubject.value,
    );
    this.filterFolderFiles();
  }

  generateImage(prompt = null) {
    this.dialog
      .open(GenerateImageComponent, {
        minHeight: 'fit-content',
        minWidth: '400px',
        disableClose: true,
        data: { prompt: prompt },
      })
      .afterClosed()
      .subscribe((response) => {
        //{newName: 'gaegaa', oldName: '', isNew: true}
        console.log(response);

        if (response?.length >= 3) {
          this.loadingData = true;

          //   console.log('Sending new folder')
          this.creativeApi
            .generateImage(response, this.selectedFolderSubject.value)
            .subscribe(
              (result) => {
                this._snackBar.open(`👏 New Image Generated!`, 'Dismiss', {
                  duration: 3000,
                });
                console.log(result);
                this.getAllAssets();
              },
              (error) => {
                console.error(error);
                this.loadingData = false;
                this._snackBar
                  .open(`Woops! Something wend wrong`, 'Try Again', {
                    duration: 3000,
                  })
                  .onAction()
                  .subscribe(() => {
                    console.log('The snackbar action was triggered!');
                    this.generateImage(response);
                  });
              },
              () => {
                this.loadingData = false;
              },
            );
        }
      });
  }

  openNewFolderDialog() {
    this.dialog
      .open(FolderNameDialog, {
        minHeight: 'fit-content',
        data: { isNew: true, oldName: null, fileMode: false },
      })
      .afterClosed()
      .subscribe((response) => {
        //{newName: 'gaegaa', oldName: '', isNew: true}
        console.log(response);
        if (response?.newName) {
          console.log('Sending new folder');
          this.assetsApi.createFolder(response?.newName).subscribe((result) => {
            this._snackBar.open(
              `👌 "${response?.newName}" Created!`,
              'Dismiss',
              { duration: 3000 },
            );
            console.log(result);
            this.getAllAssets();
          });
        }
      });
  }

  private getFilesOfCurrentFolder(newPath: string): Array<IAsset> {
    console.log('foldersubjectvalue', this.foldersSubject.value);
    if (!this.foldersSubject.value) {
      return [];
    }
    console.log('files of new path', this.foldersSubject.value[newPath]);
    return (this.foldersSubject.value[newPath] ?? []).filter((asset) => {
      return asset.type !== AssetType.FOLDER;
    });
  }
}
