<mat-stepper
  #stepper
  (selectionChange)="stepChanged($event)"
  [linear]="isLinear"
  [ngClass.mobile]=""
  id="produce-wizard-stepper">
  <mat-step [stepControl]="subjectFormGroup" label="Idea">
    <div #loadingIndicator *ngIf="isLoading" class="spinner-holder">
      <ng-lottie
        [options]="{
          path: 'assets/animations/loading-scene.json',
        }"></ng-lottie>
    </div>
    <div>
      <div *ngIf="!isLoading" id="idea-step">
        <span class="title-wrapper">
          <h2>What type of video are you creating today?</h2>
          <h6>Step 1/2</h6>
        </span>
        <div id="videoSubject">
          <div>
            <mat-chip-listbox [@fadeInOnEnter] aria-label="Tags Selection">
              <mat-chip-option
                (selectionChange)="changeVideoTag(tag)"
                *ngFor="let tag of videoTags"
                [selected]="tag === selectedVideoTag"
                [value]="tag"
                >{{ tag.title }}</mat-chip-option
              >
            </mat-chip-listbox>
          </div>

          <div class="subject-input-wrapper">
            <mat-form-field
              *ngIf="selectedVideoTag"
              [@fadeInOnEnter]
              class="subject-input">
              <textarea
                (keydown.enter)="nextStep()"
                [(ngModel)]="userPrompt"
                autocomplete="off"
                cdkTextareaAutosize
                matInput
                placeholder="{{ selectedVideoTag.text }}">
              </textarea>
              <button
                (click)="nextStep()"
                [disabled]="!userPrompt || userPrompt.trim() === ''"
                aria-label="Next"
                class="icon-last"
                color="primary"
                fxHide
                fxShow.gt-xs
                id="subject-next-button"
                mat-flat-button
                matSuffix>
                <span>Next</span>
                <mat-icon matTextSuffix> navigate_next</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!--  Next Button for mobile -->
          <div
            *ngIf="selectedVideoTag"
            [@fadeInOnEnter]
            class="mobile-next-button-wrapper"
            fxHide.gt-xs
            fxShow>
            <button
              (click)="nextStep()"
              [disabled]="!userPrompt || userPrompt.trim() === ''"
              aria-label="Next"
              class="icon-last"
              color="primary"
              id="subject-next-button"
              mat-flat-button
              matSuffix>
              <span>Next</span>
              <mat-icon matTextSuffix> navigate_next</mat-icon>
            </button>
          </div>
        </div>

        <div style="min-height: 238px">
          <div *ngIf="showSuggestions" [@fadeInOnEnter]>
            <div style="height: 10px">
              <mat-progress-bar
                *ngIf="!suggestions"
                mode="indeterminate"></mat-progress-bar>
            </div>
            <h6 class="suggestions-title">
              {{
                suggestions
                  ? 'Your Tailored prompt suggestions'
                  : 'Generating Suggestions'
              }}
            </h6>
            <mat-selection-list
              #goalsList
              [multiple]="false"
              class="suggestion-list">
              <mat-list-option
                (click)="userPrompt = suggestion"
                *ngFor="let suggestion of suggestions"
                >{{ suggestion }}</mat-list-option
              >
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
  <!-- <mat-step [stepControl]="goalsFormGroup" label="Idea">
      <div id="goals-step-inner">
        <span class="title-wrapper">
          <h2>Choose Series</h2>
          <h6>Step 2/3</h6>
        </span>
        <mat-form-field>
          <mat-select>
            <mat-option value="Series 1">Series 1</mat-option>
            <mat-option value="Series 2">Series 2</mat-option>
          </mat-select>
        </mat-form-field>
      <mat-selection-list
          class="goals-list"
          #goalsList
          [multiple]="false"
          [(ngModel)]="selectedGoals"
          (selectionChange)="selectGoal($event)"
        >
          <mat-list-option
            class="goal-wrapper"
            *ngFor="let goal of goals"
            [value]="goal"
            (mouseenter)="playAnimation(goal)"
            (mouseleave)="stopAnimation(goal)"
          >
              <video class="goal-icon" [src]="goal.icon" disabled="" #goalVideo loop></video>-->

  <!-- <div class="goal-icon-wrapper">
              <ng-lottie
                #goalIcon
                class="upload-icon"
                containerClass="goal-icon"
                [options]="{ path: goal.icon, autoplay: false }"
                (animationCreated)="animationCreated($event, goal)"
              ></ng-lottie>
            </div>
            <div class="goal-title">
              <h4>{{ goal.title }}</h4>
            </div>
            <div class="goal-description">
              <h5>{{ goal.description }}</h5>
            </div>
          </mat-list-option>
        </mat-selection-list> -->
  <!-- </div>
    </mat-step> -->

  <mat-step #formatSuggestionLoading label="load">
    <div id="loader-step">
      <loading-with-swag
        #loadingWithSwagFormat
        [steps]="formatLoadingSteps"></loading-with-swag>
    </div>
  </mat-step>
  <mat-step #formatSuggestionStep label="Idea">
    <div>
      <span class="title-wrapper">
        <h2>Choose Your Theme</h2>
        <h6>Step 2/2</h6>
      </span>

      <div>
        <design-list
          (newSuggestion)="generateNewSuggestionDesigns($event)"
          (onFormatSelected)="formatSelected($event)"
          *ngIf="stepper.selected === formatSuggestionStep"
          [displayCreators]="false"
          [privateDesigns]="privateDesigns$ | async"
          [suggestedDesigns]="suggestedDesigns$ | async"></design-list>
      </div>
    </div>
  </mat-step>
  <!--  TODO: Formats were here-->
  <mat-step #finalLoading label="load">
    <div id="loader-step">
      <loading-with-swag
        #loadingWithSwagFinal
        [steps]="finalLoadingSteps"></loading-with-swag>
    </div>
  </mat-step>
</mat-stepper>
<div *ngIf="stepper" class="buttons-area">
  <button
    (click)="backStep()"
    *ngIf="
      stepper.selectedIndex > 0 && stepper.selected !== formatSuggestionLoading
    "
    mat-button
    mat-flat-button>
    <mat-icon style="font-size: 10px; width: 10px; height: 9px"
      >arrow_back_ios
    </mat-icon>
    Back
  </button>
  <div class="dummy"></div>

  <!--  <button-->
  <!--    mat-raised-button-->
  <!--    color="primary"-->
  <!--    mat-flat-button-->
  <!--    class="finish-button"-->
  <!--    *ngIf="stepper && stepper.selectedIndex === stepper.steps.length - 2"-->
  <!--    (click)="nextStep(true)"-->
  <!--    [disabled]="!selectedFormat">-->
  <!--    Finish-->
  <!--  </button>-->
</div>
