import { environment } from 'src/environments/environment';

const BASE_URL = environment.api;

export const PROJECT_BASE_URL = BASE_URL + '/auth/project';

export const CREATE_PROJECT_POST_URL = PROJECT_BASE_URL;

export const GET_ALL_PROJECTS = PROJECT_BASE_URL + '/list';

export const GET_PROJECT_CODE = PROJECT_BASE_URL + '/code';

export const UPDATE_PROJECT_PROPERTY = PROJECT_BASE_URL;

export const CREATIVE_STATUS = '/creative-status';

export const ADD = '/add';

export function getProjectGeneralUrl(projectId: string): string {
  if (!projectId) {
    throw new Error(
      `Could not get recording scene url because recording id is null`
    );
  }
  return `${PROJECT_BASE_URL}/${projectId}`;
}
