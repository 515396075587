import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class BaseSocketService extends Socket {
  socketConfigs: SocketIoConfig;

  constructor(@Inject('CONFIG_TOKEN') config: SocketIoConfig) {
    // Inject using the token
    super(config);
    this.socketConfigs = config;
  }

  public isConnected(): boolean {
    return this.connect();
  }
}
