import { Injectable } from '@angular/core';
import { ProjectStatusEnum } from '../../../models/defines';
import { IBasicProjectData } from '../../../models/project-model';
import {
  DASHBOARD_URL,
  PANEL_OUTLET_URL,
} from '../../../pages/private/host/services/shared/constants/client/dashboard/dashboard.routes';
import { PLANNING_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/project-planning.routes';
import { STUDIO_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/studio.routes';
import { EDIT_ROOM_URL } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/edit-room.routes';
import { EXPORT_PAGE } from '../../../pages/private/host/services/shared/constants/client/dashboard/project/export-routes';
import { ProjectAuthApiService } from '../../api/auth/project-auth-api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProjectGeneralRouterService {
  // Mapping of project phases to their respective URL segments
  private phaseToUrlMap: { [key in ProjectStatusEnum]: string } = {
    [ProjectStatusEnum.PLANNING]: PLANNING_URL,
    [ProjectStatusEnum.RECORDING]: STUDIO_URL,
    [ProjectStatusEnum.EDITING]: EDIT_ROOM_URL,
    [ProjectStatusEnum.EXPORTING]: EXPORT_PAGE,
    [ProjectStatusEnum.DONE]: EXPORT_PAGE,
  };
  constructor(
    private projectApiService: ProjectAuthApiService,
    private router: Router
  ) {}

  goToAsync(goToPhase: ProjectStatusEnum, project: IBasicProjectData) {
    if (!project?.id) {
      console.warn('Project is required for navigation.');
      return;
    }

    const phaseUrl = this.phaseToUrlMap[goToPhase];
    if (!phaseUrl) {
      // Handle unknown phase
      console.error(`Unknown project phase: ${goToPhase}`);
      return;
    }
    // Update project status in async

    try {
      this.projectApiService
        .updateProjectStatus$(project?.id, project.statuses, goToPhase)
        ?.subscribe((response) => {});
    } catch (error: any) {
      console.error(
        `An error occurred while trying to update project status. error: ${error.message}`
      );
    }

    const projectOutletPath = ['project', phaseUrl, project.id];

    return this.router.navigate([
      DASHBOARD_URL,
      {
        outlets: {
          [PANEL_OUTLET_URL]: projectOutletPath,
        },
      },
    ]);
  }
}
