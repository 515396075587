import { Injectable } from '@angular/core';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
import { User } from '../models/user';
import { UserService } from './api/auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private intercomInitialized = false;
  private appKey = 'hn18clua';

  constructor(private userService: UserService) {
    if (this.userService.isMobileUser()) {
      this.hideIntercom();
    }
  }

  hideIntercom() {
    Intercom({ app_id: this.appKey, hide_default_launcher: true });
  }

  init(user: User) {
    if (!this.intercomInitialized) {
      Intercom({
        app_id: this.appKey,
        email: user.email,
        name: user.name ?? user.email,
        user_id: user.email,
      });
      this.intercomInitialized = true;
    }
  }

  show() {
    console.log('this.intercomInitialized', this.intercomInitialized);
    if (this.intercomInitialized) {
      window.Intercom('update', {
        'hide_default_launcher': false,
      });
    }
  }

  hide() {
    console.log('this.intercomInitialized', this.intercomInitialized);
    if (this.intercomInitialized) {
      window.Intercom('update', {
        'hide_default_launcher': true,
      });
    }
  }

  shutdown() {
    console.log('this.intercomInitialized', this.intercomInitialized);

    if (this.intercomInitialized) {
      this.intercomInitialized = false;
    }
    shutdown();
  }
}
