import { FunctionsHelperService } from 'src/app/services/functions-helper.service';
import { IVideoLayer } from '../take/layers/video-model';
import { IChunkIndexDBBlobObject } from 'src/app/models/indexDB.model';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import {
  IProjectDexieSchema,
  IProjectIndexData,
  SharedProjectDBService,
} from 'src/app/services/state-management/project/shared-project-indexdb.service';
import { MimeTypeEnum } from '../../defines';
import { DbService } from '../../../services/dexie/db.service';

export class VideoLayerIndexdbController {
  currentIndexDBData: IProjectDexieSchema[] = [];
  constructor(
    private readonly projectDBData: IProjectIndexData,
    private videoLayers: IVideoLayer[] = [],
    private functionsHelper: FunctionsHelperService,
    private http: HttpClient,
    private config: ConfigurationService,
    private dexieService: DbService,
    private sharedProjectDBService: SharedProjectDBService
  ) {
    this.sharedProjectDBService.currentProjectIndexData$.subscribe({
      next: (data) => {
        this.currentIndexDBData = data ?? [];
      },
    });
  }

  public async setVideoLayersLocalFilePathAsync() {
    if (!this.videoLayers || this.videoLayers.length === 0) {
      return;
    }

    const timeoutPromise = this.timeoutPromise();

    await Promise.race([
      (async () => {
        await this.projectDBData.dexiePromise;

        await Promise.all(
          this.videoLayers.map((videoLayer) =>
            this.setVideoLayerLocalFilePathAsync(videoLayer)
          )
        );
      })(),
      timeoutPromise,
    ]);
  }

  /// In case we want to convert only 1 video layer
  public async setVideoLayerLocalFilePathAsync(videoLayer: IVideoLayer) {
    if (!videoLayer?.uploadPath) {
      return;
    }
    await this.projectDBData.dexiePromise;

    return this.processVideoLayer(videoLayer);
  }

  public async updateLocalRecordingIndexDBAsync(
    uniqueId: string,
    arrayBuffer: ArrayBuffer
  ) {
    if (!uniqueId || !arrayBuffer) {
      return;
    }
    const timeoutPromise = this.timeoutPromise();

    // Race the actual operation against the timeout
    await Promise.race([
      (async () => {
        await this.projectDBData.dexiePromise;
        const localRecordingIndexDBObject: IProjectDexieSchema = {
          id: uniqueId,
          position: 0,
          arrayBuffer: arrayBuffer,
        };
        return this.updateProjectAsync(
          this.projectDBData.dbName,
          this.projectDBData.storeName,
          uniqueId,
          localRecordingIndexDBObject
        );
      })(),
      timeoutPromise,
    ]);
  }

  private timeoutPromise() {
    // Create a promise that rejects after 1500 milliseconds
    const timeoutPromise = new Promise((_, reject) => {
      const setTimeoutId = setTimeout(() => {
        console.warn('update local recording was hanging in air for too long');
        reject(
          new Error('update local recording was hanging in air for too long')
        );
      }, 1500);

      // Clear the timeout if the operation completes successfully
      this.projectDBData.dexiePromise.finally(() => clearTimeout(setTimeoutId));
    });
    return timeoutPromise;
  }

  private async processVideoLayer(videoLayer: IVideoLayer): Promise<void> {
    if (!videoLayer.uploadPath) {
      return;
    }

    try {
      try {
        const fileData = await this.getFileDataAsync(videoLayer.uinuqeId);

        if (fileData) {
          this.setLocalUploadPath(videoLayer, fileData.arrayBuffer);
        } else {
          await this.fetchAndStoreVideoLayerAsync(videoLayer);
        }
      } catch (error) {
        await this.fetchAndStoreVideoLayerAsync(videoLayer);
      }
    } catch (error) {
      console.error(`Could not get the video layer locally. Error: ${error}`);
    }
  }

  private async getFileDataAsync(
    videoLayerId: string
  ): Promise<IProjectDexieSchema> {
    try {
      let data: IProjectDexieSchema[] = [];
      if (this.currentIndexDBData.length > 0) {
        data = this.currentIndexDBData;
      } else {
        data = await this.getProjectDataAsync(
          this.projectDBData.dbName,
          this.projectDBData.storeName
        );
      }
      const currentVideo = data.find((video) => video.id === videoLayerId);
      return currentVideo;
    } catch (error) {
      console.error(
        `Error fetching file data for videoLayerId ${videoLayerId}: ${error}`
      );
      return null;
    }
  }

  public setLocalUploadPath(
    videoLayer: IVideoLayer,
    arrayBuffer: ArrayBuffer
  ): void {
    const blob = this.functionsHelper.arrayBufferToBlob(
      arrayBuffer,
      'video/mp4'
    );
    this.configLocalPath(blob, videoLayer, MimeTypeEnum.VideoMp4);
  }

  private async fetchAndStoreVideoLayerAsync(
    videoLayer: IVideoLayer
  ): Promise<void> {
    try {
      const blob = await firstValueFrom(
        this.http.get(this.config.baseCdnUrl + videoLayer.uploadPath, {
          responseType: 'blob',
          params: { 'no-auth': true },
        })
      );
      const mimeType = this.functionsHelper.getMimeTypeFromExtension(
        videoLayer.uploadPath
      );

      const videoBlob = new Blob([blob], { type: mimeType });

      this.configLocalPath(videoBlob, videoLayer, mimeType);

      const arrayBuffer =
        await this.functionsHelper.blobToArrayBufferAsync(blob);
      const indexDBBlobObject: IChunkIndexDBBlobObject = {
        id: videoLayer.uinuqeId,
        position: 0,
        arrayBuffer: arrayBuffer,
      };

      await this.addTableToProjectAsync(indexDBBlobObject);
    } catch (error) {
      console.error(
        `Error fetching and storing video layer for videoLayerId ${videoLayer.id}: ${error}`
      );
    }
  }

  private configLocalPath(
    videoBlob: Blob,
    videoLayer: IVideoLayer,
    mimeType: MimeTypeEnum
  ) {
    const localVideoUrl = URL.createObjectURL(videoBlob);
    const safeLocalUrl =
      this.functionsHelper.transformLocalUrlToSafe(localVideoUrl);
    videoLayer.localUrlConfigs = {
      url: safeLocalUrl,
      type: mimeType,
    };
  }

  async getProjectDataAsync(dbName: string, tableName: string) {
    return this.dexieService.getTableDataAsync<IProjectDexieSchema>(
      dbName,
      tableName
    );
  }

  async addTableToProjectAsync(updates: IProjectDexieSchema): Promise<void> {
    try {
      await this.dexieService.addRecordAsync<IProjectDexieSchema>(
        this.projectDBData.dbName,
        this.projectDBData.storeName,
        updates
      );
      console.log('Project updated');
    } catch (err) {
      console.error('Failed to update project', err);
    }
  }

  public async removeTableFromProjectAsync() {
    try {
      await this.projectDBData.dexiePromise;
      console.log(`VIDEO LAYERS!`, this.videoLayers);
      for (const videoLayer of this.videoLayers) {
        await this.dexieService.deleteRecordAsync(
          this.projectDBData.dbName,
          this.projectDBData.storeName,
          videoLayer.uinuqeId
        );
      }
    } catch (dexieError) {
      console.error(
        `An error occurred when trying to delete record from indexdb. Error:`,
        dexieError
      );
    }
  }

  async updateProjectAsync(
    dbName: string,
    tableName: string,
    id: string,
    updates: Partial<IProjectDexieSchema>
  ): Promise<void> {
    try {
      await this.dexieService.updateRecordAsync<IProjectDexieSchema>(
        dbName,
        tableName,
        id,
        updates
      );
      console.log('Project updated');
    } catch (err) {
      console.error('Failed to update project', err);
    }
  }
}
