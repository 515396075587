import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, timer } from 'rxjs';
import {
  CreativeStatusEnum,
  IComposition,
  ILaunchFormQuestionConfigs,
  ProjectStatusEnum,
} from 'src/app/models/defines';

import {
  IProjectInDTO,
  IProjectStatuses,
  ProjectPropertiesToUpdateEnum,
} from 'src/app/models/project-model';
import { getProjectSpecificSceneUrl } from 'src/app/constants/private/urls/projects/scene.urls';
import { ISceneInDTO } from 'src/app/models/project/scene-model';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { IAddSceneResponse } from '../../http-models/project/scene/scene-http-response-model';
import {
  CREATE_PROJECT_POST_URL,
  CREATIVE_STATUS,
  GET_ALL_PROJECTS,
  GET_PROJECT_CODE,
  getProjectGeneralUrl,
  PROJECT_BASE_URL,
} from 'src/app/constants/private/urls/projects/project.urls';

export interface INewProjectRequest {
  prompt: string;
  videoTagId: string;
  designGroupId: string;
}

export interface ILaunchNewProjectRequest {
  answers: ILaunchFormQuestionConfigs[];
}

@Injectable({
  providedIn: 'root',
})
export class ProjectAuthApiService {
  public MAX_RETRY_ATTEMPTS = 5;
  public isLoadingExportData$ = new BehaviorSubject<boolean>(false);

  isLoadingStructure$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public getAllProjects$() {
    return this.http.get<IProjectInDTO[]>(GET_ALL_PROJECTS);
  }

  public getProjectByCode$(projectCode: number) {
    const params = new HttpParams().set('projectCode', projectCode);
    return this.http.get<IProjectInDTO>(GET_PROJECT_CODE, { params });
  }

  public getProjectById$(projectId: string) {
    return this.http.get<IProjectInDTO>(PROJECT_BASE_URL + '/' + projectId);
  }

  updateProjectProperty$(params: {
    projectId: string;
    key: string;
    value: any;
  }) {
    const putPropertyToProjectUrl = getProjectGeneralUrl(params.projectId);
    return this.http.put<IProjectInDTO>(putPropertyToProjectUrl, params);
  }

  updateSceneProperty$(params: {
    projectId: string;
    sceneId: string;
    key: string;
    value: any;
  }) {
    const putPropertyToProjectUrl = getProjectSpecificSceneUrl(
      params.projectId,
      params.sceneId
    );
    return this.http.put<ISceneInDTO>(putPropertyToProjectUrl, params);
  }

  deleteScene$(projectId: string, sceneId: string) {
    const putPropertyToProjectUrl = getProjectSpecificSceneUrl(
      projectId,
      sceneId
    );
    return this.http.delete<boolean>(putPropertyToProjectUrl);
  }

  addScene$(
    projectId: string,
    sceneId: string,
    params: {
      composition: IComposition;
      relativePositin: string;
    }
  ) {
    const putPropertyToProjectUrl = getProjectSpecificSceneUrl(
      projectId,
      sceneId
    );
    return this.http.post<IAddSceneResponse>(putPropertyToProjectUrl, params);
  }

  public createNewProject$(creativeRequest: INewProjectRequest) {
    return this.http.post<IProjectInDTO>(
      CREATE_PROJECT_POST_URL,
      creativeRequest
    );
  }

  public getProjectStatus$(projectId: string) {
    return this.http.get<CreativeStatusEnum>(
      PROJECT_BASE_URL + '/' + projectId + CREATIVE_STATUS
    );
  }

  shouldRetry(error: HttpErrorResponse, attempt: number) {
    const shouldRetry =
      Math.floor(error.status / 100) !== 5 && error.status !== 429;
    return shouldRetry ? timer((attempt + 1) * 2000) : throwError(() => error);
  }

  public updateProjectStatus$(
    projectId: string,
    currentStatuses: IProjectStatuses,
    newStatus: ProjectStatusEnum
  ): Observable<IProjectInDTO> | null {
    if (!projectId || !newStatus) {
      throw new MissingArgumentsError(
        `Could not update project status because one of the arguments is null`
      );
    }
    if (currentStatuses.general) {
      const statuses = Object.keys(ProjectStatusEnum).map(
        (key) => ProjectStatusEnum[key as keyof typeof ProjectStatusEnum]
      );
      // Get index of current status and 'recording' status
      const currentIndex = statuses.indexOf(currentStatuses.general);
      const recordingIndex = statuses.indexOf(newStatus);
      if (currentIndex > recordingIndex) {
        console.log(
          `No status update for project needed. Current status is already ${currentStatuses.general} or beyond.`
        );
        return null;
      }
    }
    const params = {
      projectId: projectId,
      value: {
        general: newStatus,
        transcript: currentStatuses.transcript,
      },
      key: ProjectPropertiesToUpdateEnum.STATUS,
    };
    return this.updateProjectProperty$(params);
  }
}
