import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JoyrideModule } from 'ngx-joyride';
import { A11yModule } from '@angular/cdk/a11y';
import { NavbarComponent } from './components/major/navbar/navbar.component';
import { RightPaneComponent } from './components/right-pane/right-pane.component';
import { MainComponent } from './pages/public/main/main.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './configurations/token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { VideoToastComponent } from './components/major/video-toast/video-toast.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EmojiPipe } from './pipes/emoji.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { AskToParticipateComponent } from './components/dialogs/ask-to-join/ask-to-participate.component';
import { MouseWheelHelper } from './configurations/MouseWheelHelper';
import { LockedFeatureComponent } from './components/dialogs/locked-feature/locked-feature.component';
import { NonPcErrorComponent } from './components/minor/non-pc-error/non-pc-error.component';
import { UserMediaSelectorComponent } from './components/minor/user-media-selector/user-media-selector.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ChatComponent } from './components/major/chat/chat.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChatInputComponent } from './components/major/chat/chat-input/chat-input.component';
import { VideoChatCommentComponent } from './components/major/video-chat-comment/video-chat-comment.component';
import { HostTesterComponent } from './pages/public/host-tester/host-tester.component';
import { WebsocketService } from './pages/private/host/services/websockets.service';
import { ContentService } from './services/api/content.service';
import { ShareLinksComponent } from './components/dialogs/share-links/share-links.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { PrivacyComponent } from './pages/public/others/privacy/privacy.component';
import { TermsComponent } from './pages/public/others/terms/terms.component';
import { HomepageWpComponent } from './pages/public/homepage-wp/homepage-wp.component';
import { MinuteSecondsPipe, MsToMinSecPipe } from './pipes/time-transform.pipe';
import { TruncatePipe } from './pipes/truncatePipe.pipe';
import { ConnectionErrorPageComponent } from './pages/public/connection-error-page/connection-error-page.component';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { DesignListComponent } from './components/dialogs/design-list/design-list.component';
import { SimpleLoadingComponent } from './components/minor/simple-loading/simple-loading.component';
import { RotateScreenComponent } from './components/dialogs/rotate-screen/rotate-screen.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { DashboardComponent } from './pages/private/dashboard/dashboard.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { BrandingComponent } from './pages/private/dashboard/branding/branding.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { AiPrompterComponent } from './components/major/teleprompter/ai-prompter/ai-prompter.component';
import { LoadingWithSwagComponent } from './components/minor/loading-with-swag/loading-with-swag.component';
import { AssetsComponent } from './pages/private/dashboard/assets/assets.component';
import { BackstageComponent } from './components/major/backstage/backstage.component';
import { DiscoverComponent } from './pages/private/dashboard/discover/discover.component';
import { TeamUserPicComponent } from './components/major/backstage/team/team-user-pic/team-user-pic.component';
import { ReplaceBackstagePositionComponent } from './components/dialogs/replace-backstage-position/replace-backstage-position.component';
import { OrderByPositionPipePipe } from './pipes/peerOrderByPosition.pipe';
import { LottieTesterComponent } from './pages/public/lottie-tester/lottie-tester.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ApplyPrompterTextComponent } from './components/dialogs/apply-prompter-text/apply-prompter-text.component';
import { AssetsMainComponent } from './pages/private/dashboard/assets/assets-main/assets-main.component';
import { ImageSelectorComponent } from './components/minor/image-selector/image-selector.component';
import { FolderNameDialog } from './components/dialogs/folder-name/folder-name-dialog.component';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { GenerateImageComponent } from './components/dialogs/generate-image/generate-image.component';
import { VerticalLineDirective } from './directives/vertical-line.directive';
import { ScreenShareComponent } from './components/major/backstage/team/screen-share/screen-share.component';
import { DynamicAssetContentComponent } from './pages/private/dashboard/assets/dynamic-asset-content/dynamic-asset-content.component';
import { SwitchCaseDirective } from './directives/switch-case.directive';
import { VideoSelectorComponent } from './components/minor/video-selector/video-selector.component';
import { VideoForBackstageComponent } from './components/major/backstage/team/video-for-backstage/video-for-backstage.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { dashUrlResolver } from './directives/dash-url-resolver.directive';
import { ExportDialogComponent } from './pages/private/dashboard/project/edit-room/export-dialog/export-dialog.component';
import { PlayButtonComponent } from './components/play-button/play-button.component';
import { LottieCleanupDirective } from './directives/lottie-cleanup.directive';
import { LottiePreviewDialogComponent } from './components/minor/lottie-preview-dialog/lottie-preview-dialog.component';
import { VideoPlayerComponent } from './components/minor/video-player/video-player.component';
import { MaterialModule } from './modules/material/material.module';
import { SharedModule } from './modules/shared/shared.module';
import { WalkthroughPanelComponent } from './components/walkthrough-panel/walkthrough-panel.component';
import { PauseDialogComponent } from './components/dialogs/pause-dialog/pause-dialog.component';
import { DialogWrapperComponent } from './components/dialogs/dialog-wrapper/dialog-wrapper.component';
import { MyLibraryComponent } from './pages/private/dashboard/project/my-library/my-library.component';
import { TakeComponent } from './pages/private/dashboard/project/shared-components/take/take.component';

import { FinishRecordingDialogComponent } from './components/dialogs/finish-record-demo-dialog/finish-recording-dialog.component';
import { UploadIndicatorComponent } from './components/minor/upload-indicator/upload-indicator.component';
import { ProjectWizardComponent } from './pages/private/dashboard/project/new-project/project-wizard/project-wizard.component';
import { ActionsPanelComponent } from './pages/private/dashboard/project/shared-components/actions-panel/actions-panel.component';
import { SceneSetupDialogComponent } from './components/dialogs/scene-setup-dialog/scene-setup-dialog.component';
import { DeleteSceneConfirmComponent } from './components/dialogs/delete-scene-confirm/delete-scene-confirm.component';
import { StopRecordingAlertDialogComponent } from './components/dialogs/stop-recording-alert-dialog/stop-recording-alert-dialog.component';
import { SceneBankComponent } from './components/dialogs/scene-bank/scene-bank.component';
import { DevicesPermissionsComponent } from './components/minor/devices-permissions/devices-permissions.component';
import { ConfirmationModalComponent } from './components/dialogs/confirmation-modal/confirmation-modal.component';

import { NgxStripeModule } from 'ngx-stripe';
import { PlansComponent } from './components/major/payments/plans/plans.component';
import { PaymentFormComponent } from './components/major/payments/payment-form/payment-form.component';
import { AudioListComponent } from './pages/private/dashboard/project/edit-room/tabs/audio-list/audio-list.component';
import { FeedbackDialogComponent } from './components/dialogs/feedback-dialog/feedback-dialog.component';
import { DeviceInUseComponent } from './components/dialogs/device-in-use/device-in-use.component';
import { StartRecordDialogComponent } from './components/dialogs/start-record-dialog/start-record-dialog.component';
import { NewProjectComponent } from './pages/private/dashboard/project/new-project/new-project.component';
import { ReplaceTakeDialogComponent } from './components/dialogs/replace-take-dialog/replace-take-dialog.component';
import { WelcomeDialogComponent } from './components/dialogs/welcome-dialog/welcome-dialog.component';
import { PaymentDialogComponent } from './components/dialogs/plans-and-payments/payment-dialog/payment-dialog.component';
import { PlansPageComponent } from './pages/private/dashboard/plans-and-payments/plans-page/plans-page.component';
import { ProjectCardComponent } from './components/cards/project-card/project-card.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(
    /* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RightPaneComponent,
    MainComponent,
    TruncatePipe,
    VideoToastComponent,
    EmojiPipe,
    FilterPipe,
    PauseDialogComponent,
    OrderByPipe,

    OrderByPositionPipePipe,
    MinuteSecondsPipe,

    AskToParticipateComponent,
    VideoPlayerComponent,
    LockedFeatureComponent,
    NonPcErrorComponent,
    UserMediaSelectorComponent,
    UploadIndicatorComponent,
    LottiePreviewDialogComponent,
    ChatComponent,
    ChatInputComponent,
    VideoChatCommentComponent,
    HostTesterComponent,
    ShareLinksComponent,
    DynamicAssetContentComponent,

    PrivacyComponent,
    TermsComponent,
    HomepageWpComponent,
    ConnectionErrorPageComponent,
    SafeUrlPipe,
    DesignListComponent,
    SimpleLoadingComponent,
    ScreenShareComponent,
    RotateScreenComponent,
    DashboardComponent,
    SideNavComponent,
    ConfirmationModalComponent,
    BrandingComponent,
    FileDropDirective,
    LottieCleanupDirective,
    ProjectWizardComponent,
    AiPrompterComponent,
    LoadingWithSwagComponent,
    AssetsComponent,
    DiscoverComponent,
    BackstageComponent,
    TeamUserPicComponent,
    PlayButtonComponent,
    ReplaceBackstagePositionComponent,
    LottieTesterComponent,
    ApplyPrompterTextComponent,
    AssetsMainComponent,
    ImageSelectorComponent,
    FolderNameDialog,
    ConfirmComponent,
    GenerateImageComponent,

    VerticalLineDirective,

    SwitchCaseDirective,
    VideoSelectorComponent,
    VideoForBackstageComponent,

    dashUrlResolver,
    ExportDialogComponent,
    WalkthroughPanelComponent,
    DialogWrapperComponent,
    MyLibraryComponent,
    TakeComponent,
    FinishRecordingDialogComponent,
    ActionsPanelComponent,

    SceneSetupDialogComponent,
    DevicesPermissionsComponent,
    DeleteSceneConfirmComponent,
    StopRecordingAlertDialogComponent,
    SceneBankComponent,

    PlansComponent,
    PlansPageComponent,
    PaymentFormComponent,
    AudioListComponent,
    FeedbackDialogComponent,
    DeviceInUseComponent,
    StartRecordDialogComponent,
    NewProjectComponent,
    ProjectCardComponent,

    ReplaceTakeDialogComponent,
    WelcomeDialogComponent,
    PaymentDialogComponent,
  ],
  imports: [
    // BrowserModule.withServerTransition({appId: 'serverApp'}),
    RoundProgressModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    JoyrideModule.forRoot(),
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    A11yModule,

    // ProjectModule,
    // ProjectGeneralModule, // Scoped with '/general' namespace
    ToastrModule.forRoot({
      closeButton: true, // Enable the close button
      preventDuplicates: true, // Avoid duplicate toasts
      enableHtml: true, // Enable HTML in the toast message
    }),
    NgxChartsModule,
    ClipboardModule,
    NgxSpinnerModule,

    GoogleTagManagerModule.forRoot({
      id: 'GTM-W6KHFQ7',
    }),
    RecaptchaV3Module,
    AppRoutingModule,
    NgxStripeModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    MouseWheelHelper,
    WebsocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Ld9ZjYoAAAAAFpqHVjv8m4f69KGlVaQHceRsHQu',
    },

    ContentService,
  ],
  bootstrap: [AppComponent],
  exports: [FilterPipe],
})
export class AppModule {}
