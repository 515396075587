import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

import { BehaviorSubject } from 'rxjs';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { UserService } from '../api/auth/user.service';
import { LoggerService } from '../logger.service';
import { productFruits } from 'product-fruits';

const TOKEN_KEY = 'auth-token';
const ME_KEY = 'me';

export enum AuthenticateResponses {
  AUTHENTICATED = 'Authenticated',
  INVALID = 'Invalid',
  SERVER_NOT_RESPONSIVE = 'ServerNotResponsive',
}

export interface IHubspotParams {
  [key: string]: string;
}

export interface IUtm {
  utmKey: string;
  utmValue: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private httpClient: HttpClient;
  INVITE_KEY = 'InviteID';

  isAuthenticated$ = new BehaviorSubject(false);
  userInfoState = new BehaviorSubject(null);
  isProductFruitsInitalized = false;
  public authToken;
  public user: User;

  constructor(
    private router: Router,
    private handler: HttpBackend,
    private userApi: UserService,
    private config: ConfigurationService,
    private logger: LoggerService
  ) {
    // To avoid circular dep with the TOKEN interceptor
    this.httpClient = new HttpClient(handler);

    // this.checkTokenAndUpdateUser();
    this.getUser();
  }

  async authorize(
    isInitalize: boolean
  ): Promise<{ response: AuthenticateResponses; user: User }> {
    // Try to get the user from /me endpoint
    let token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      this.logger.debug('No token found');
      return { response: AuthenticateResponses.INVALID, user: null };
    }
    try {
      let user: User = await this.userApi.me();

      if (user.token) {
        this.login(user.token);
      }
      if (user.email) {
        user.isShuffllUser = user.email.toLowerCase().includes('shuffll');
      }

      // this.logger.debug('Me',user);
      // console.log('me',user);
      this.saveUser(user);
      this.isAuthenticated$.next(true);
      if (isInitalize) {
        this.initProductFruits(user);
      }

      return { response: AuthenticateResponses.AUTHENTICATED, user: user };
    } catch (error) {
      this.logger.error(error);
      if (!error.ok && error.status === 0) {
        // Means the server is unreachable
        return {
          response: AuthenticateResponses.SERVER_NOT_RESPONSIVE,
          user: null,
        };
      } else return { response: AuthenticateResponses.INVALID, user: null };
    }

    // .toPromise()
    // .then(response => {
    //   let user = response ? response['user'] : null;
    //   if (user) {
    //     console.log(user);
    //     this.saveUser(user);
    //     this.authenticationState.next(true);
    //     return true;
    //   } else {
    //     console.error('user not authorized');
    //     () => false
    //   }
    //
    // })
    // .catch(() => false);
  }

  private initProductFruits(user: User) {
    if (this.isProductFruitsInitalized || this.userApi.isMobileUser()) return;
    try {
      const language = navigator.language.slice(0, 2); // Extract the first two characters

      productFruits.init(this.config.productFruitsWorkspaceId, language, {
        email: user.email ?? '',
        username: user.name ?? '',
      });
      this.isProductFruitsInitalized = true;
    } catch (productFruitsError) {
      console.error(
        `Could not initalize product fruits. Error:`,
        productFruitsError
      );
    }
  }

  /***
   * If a token exists, the function will try to retreive data from the server.
   * in case the token is valid, it will update the users data.
   * otherwise, it will log the user off.
   */
  // checkTokenAndUpdateUser() {
  //   // Check if the token exists in localstorage
  //   let token = localStorage.getItem(TOKEN_KEY);
  //   if (token) {
  //     try {
  //
  //       // Call the ME api to check the token and get the users data.
  //       this.httpClient.post(this.config.apiIP + '/user/me', '',
  //         {headers: {Authorization: `Bearer ${token}`}})
  //         .subscribe((response: any) => {
  //           // We got a user back
  //             if (response?.user) {
  //               console.log(response.user);
  //               this.saveUser(response.user);
  //               this.authenticationState.next(true);
  //             } else {
  //             /// There was an error.
  //             console.error(response);
  //
  //             // The token was invalid
  //             if (response.errorCode === 401) {
  //               this.logout(true);
  //               this.authenticationState.next(false);
  //             }
  //           }
  //         });
  //     } catch (error) {
  //       console.log(error);
  //       throw error;
  //     }
  //
  //
  //   } else {
  //     this.authenticationState.next(false);
  //   }
  // }

  setInviteId(inviteId: string) {
    localStorage.setItem(this.INVITE_KEY, inviteId);
  }

  login(token: string) {
    this.authToken = token;
    this.isAuthenticated$.next(true);
    return localStorage.setItem(TOKEN_KEY, token);
  }

  goToConnectionErrorPage(url: string) {
    console.warn('Looks like the server did not respond to our auth request');
    this.isAuthenticated$.next(false);
    this.router.navigate(['maintenance']);
  }

  logout(redirectURL = '/') {
    console.log('logging out');
    localStorage.removeItem(TOKEN_KEY);
    this.isAuthenticated$.next(false);

    /// Split url params
    let splitFromUrlParams = redirectURL.split('?');
    redirectURL = splitFromUrlParams[0];

    let paramObj = {};
    try {
      /// Create an object from the key=value url params string
      const urlParams =
        splitFromUrlParams.length > 0 ? splitFromUrlParams[1] : '';
      const urlParamsObj = new URLSearchParams(urlParams);

      urlParamsObj.forEach(function (value, key) {
        paramObj[key] = value;
      });
      // console.log('urlParams', urlParams)
      // console.log('paramObj', paramObj)
    } catch (ex) {
      console.error(ex);
    }

    if (redirectURL) {
      this.goToLogin({ returnUrl: redirectURL, ...paramObj });
    }
  }

  checkUserName(userName: string) {
    return this.httpClient.post<any>(`${this.config.apiIP}/checkusername`, {
      username: userName,
    });
  }

  checkEmail(email: string) {
    return this.httpClient.post<any>(`${this.config.apiIP}/checkemail`, {
      email: email,
    });
  }

  signIn(email: string, additionalParamsForHubspot: IHubspotParams) {
    return this.httpClient.post<any>(
      `${this.config.apiIP}/user/sign-in`,
      {
        email,
        additionalParamsForHubspot,
      },
      { withCredentials: true }
    );
  }

  isTokenPresent(): boolean {
    return !!this.getToken();
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  getUserWithoutToken(): User {
    let user = this.getUser();
    user.token = null;
    return user;
  }

  getUser(): User {
    if (!this.user) {
      // Retrieve from localstorage if user is not set-up yet
      this.user = JSON.parse(localStorage.getItem(ME_KEY));
    }
    return this.user;
  }

  isAdminByEmail(): boolean {
    if (!this.user) return;
    const domain = this.getDomainFromEmail(this.user.email);
    if (domain === 'shuffll.com') return true;

    return false;
  }

  getDomainFromEmail(email: string): string | null {
    if (email) {
      const atIndex = email.indexOf('@');
      if (atIndex !== -1) {
        return email.slice(atIndex + 1);
      }
    }
    return null;
  }

  saveUser(user: User) {
    this.user = user;
    localStorage.setItem(ME_KEY, JSON.stringify(user));
    // console.log("localstorgae here")
  }

  goToLogin(extraParams: any = {}) {
    // console.log(this.router)
    // console.log('routing to sign-in from: ', this.router.url);

    this.router.navigate(['login'], {
      queryParams: { returnUrl: this.router.url, ...extraParams },
    });
  }

  updateLocalUserVerification(isVerified: boolean) {
    this.saveUser(this.user);
  }
}
